exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-become-a-standup-champion-js": () => import("./../../../src/pages/become-a-standup-champion.js" /* webpackChunkName: "component---src-pages-become-a-standup-champion-js" */),
  "component---src-pages-getting-xywav-js": () => import("./../../../src/pages/getting-xywav.js" /* webpackChunkName: "component---src-pages-getting-xywav-js" */),
  "component---src-pages-idiopathic-hypersomnia-dosing-js": () => import("./../../../src/pages/idiopathic-hypersomnia/dosing.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-dosing-js" */),
  "component---src-pages-idiopathic-hypersomnia-faq-js": () => import("./../../../src/pages/idiopathic-hypersomnia/faq.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-faq-js" */),
  "component---src-pages-idiopathic-hypersomnia-find-a-doctor-js": () => import("./../../../src/pages/idiopathic-hypersomnia/find-a-doctor.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-find-a-doctor-js" */),
  "component---src-pages-idiopathic-hypersomnia-getting-xywav-js": () => import("./../../../src/pages/idiopathic-hypersomnia/getting-xywav.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-getting-xywav-js" */),
  "component---src-pages-idiopathic-hypersomnia-ih-condition-js": () => import("./../../../src/pages/idiopathic-hypersomnia/ih-condition.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-ih-condition-js" */),
  "component---src-pages-idiopathic-hypersomnia-ih-sign-up-thank-you-js": () => import("./../../../src/pages/idiopathic-hypersomnia/ih-sign-up-thank-you.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-ih-sign-up-thank-you-js" */),
  "component---src-pages-idiopathic-hypersomnia-index-js": () => import("./../../../src/pages/idiopathic-hypersomnia/index.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-index-js" */),
  "component---src-pages-idiopathic-hypersomnia-jazzcares-for-xywav-js": () => import("./../../../src/pages/idiopathic-hypersomnia/jazzcares-for-xywav.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-jazzcares-for-xywav-js" */),
  "component---src-pages-idiopathic-hypersomnia-patient-support-programs-js": () => import("./../../../src/pages/idiopathic-hypersomnia/patient-support-programs.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-patient-support-programs-js" */),
  "component---src-pages-idiopathic-hypersomnia-resources-js": () => import("./../../../src/pages/idiopathic-hypersomnia/resources.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-resources-js" */),
  "component---src-pages-idiopathic-hypersomnia-side-effects-js": () => import("./../../../src/pages/idiopathic-hypersomnia/side-effects.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-side-effects-js" */),
  "component---src-pages-idiopathic-hypersomnia-sleep-inertia-js": () => import("./../../../src/pages/idiopathic-hypersomnia/sleep-inertia.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-sleep-inertia-js" */),
  "component---src-pages-idiopathic-hypersomnia-stay-connected-js": () => import("./../../../src/pages/idiopathic-hypersomnia/stay-connected.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-stay-connected-js" */),
  "component---src-pages-idiopathic-hypersomnia-talking-to-your-doctor-js": () => import("./../../../src/pages/idiopathic-hypersomnia/talking-to-your-doctor.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-talking-to-your-doctor-js" */),
  "component---src-pages-idiopathic-hypersomnia-treating-ih-js": () => import("./../../../src/pages/idiopathic-hypersomnia/treating-ih.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-treating-ih-js" */),
  "component---src-pages-idiopathic-hypersomnia-what-is-xywav-js": () => import("./../../../src/pages/idiopathic-hypersomnia/what-is-xywav.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-what-is-xywav-js" */),
  "component---src-pages-idiopathic-hypersomnia-xywav-efficacy-js": () => import("./../../../src/pages/idiopathic-hypersomnia/xywav-efficacy.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-xywav-efficacy-js" */),
  "component---src-pages-idiopathic-hypersomnia-your-ih-experience-js": () => import("./../../../src/pages/idiopathic-hypersomnia/your-ih-experience.js" /* webpackChunkName: "component---src-pages-idiopathic-hypersomnia-your-ih-experience-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jazzcares-for-xywav-js": () => import("./../../../src/pages/jazzcares-for-xywav.js" /* webpackChunkName: "component---src-pages-jazzcares-for-xywav-js" */),
  "component---src-pages-narcolepsy-dosing-js": () => import("./../../../src/pages/narcolepsy/dosing.js" /* webpackChunkName: "component---src-pages-narcolepsy-dosing-js" */),
  "component---src-pages-narcolepsy-educational-webinars-js": () => import("./../../../src/pages/narcolepsy/educational-webinars.js" /* webpackChunkName: "component---src-pages-narcolepsy-educational-webinars-js" */),
  "component---src-pages-narcolepsy-faq-js": () => import("./../../../src/pages/narcolepsy/faq.js" /* webpackChunkName: "component---src-pages-narcolepsy-faq-js" */),
  "component---src-pages-narcolepsy-find-narcolepsy-doctor-js": () => import("./../../../src/pages/narcolepsy/find-narcolepsy-doctor.js" /* webpackChunkName: "component---src-pages-narcolepsy-find-narcolepsy-doctor-js" */),
  "component---src-pages-narcolepsy-getting-xywav-js": () => import("./../../../src/pages/narcolepsy/getting-xywav.js" /* webpackChunkName: "component---src-pages-narcolepsy-getting-xywav-js" */),
  "component---src-pages-narcolepsy-index-js": () => import("./../../../src/pages/narcolepsy/index.js" /* webpackChunkName: "component---src-pages-narcolepsy-index-js" */),
  "component---src-pages-narcolepsy-jazzcares-for-xywav-js": () => import("./../../../src/pages/narcolepsy/jazzcares-for-xywav.js" /* webpackChunkName: "component---src-pages-narcolepsy-jazzcares-for-xywav-js" */),
  "component---src-pages-narcolepsy-living-with-narcolepsy-js": () => import("./../../../src/pages/narcolepsy/living-with-narcolepsy.js" /* webpackChunkName: "component---src-pages-narcolepsy-living-with-narcolepsy-js" */),
  "component---src-pages-narcolepsy-lower-sodium-oxybate-treatment-option-js": () => import("./../../../src/pages/narcolepsy/lower-sodium-oxybate-treatment-option.js" /* webpackChunkName: "component---src-pages-narcolepsy-lower-sodium-oxybate-treatment-option-js" */),
  "component---src-pages-narcolepsy-patient-resources-js": () => import("./../../../src/pages/narcolepsy/patient-resources.js" /* webpackChunkName: "component---src-pages-narcolepsy-patient-resources-js" */),
  "component---src-pages-narcolepsy-patient-support-programs-js": () => import("./../../../src/pages/narcolepsy/patient-support-programs.js" /* webpackChunkName: "component---src-pages-narcolepsy-patient-support-programs-js" */),
  "component---src-pages-narcolepsy-stay-connected-js": () => import("./../../../src/pages/narcolepsy/stay-connected.js" /* webpackChunkName: "component---src-pages-narcolepsy-stay-connected-js" */),
  "component---src-pages-narcolepsy-talk-to-your-doctor-js": () => import("./../../../src/pages/narcolepsy/talk-to-your-doctor.js" /* webpackChunkName: "component---src-pages-narcolepsy-talk-to-your-doctor-js" */),
  "component---src-pages-narcolepsy-transitioning-from-xyrem-js": () => import("./../../../src/pages/narcolepsy/transitioning-from-xyrem.js" /* webpackChunkName: "component---src-pages-narcolepsy-transitioning-from-xyrem-js" */),
  "component---src-pages-narcolepsy-what-is-xywav-js": () => import("./../../../src/pages/narcolepsy/what-is-xywav.js" /* webpackChunkName: "component---src-pages-narcolepsy-what-is-xywav-js" */),
  "component---src-pages-narcolepsy-xywav-side-effects-js": () => import("./../../../src/pages/narcolepsy/xywav-side-effects.js" /* webpackChunkName: "component---src-pages-narcolepsy-xywav-side-effects-js" */),
  "component---src-pages-patient-stories-js": () => import("./../../../src/pages/patient-stories.js" /* webpackChunkName: "component---src-pages-patient-stories-js" */),
  "component---src-pages-patient-support-programs-js": () => import("./../../../src/pages/patient-support-programs.js" /* webpackChunkName: "component---src-pages-patient-support-programs-js" */),
  "component---src-pages-sitemap-consumer-js": () => import("./../../../src/pages/sitemap-consumer.js" /* webpackChunkName: "component---src-pages-sitemap-consumer-js" */),
  "component---src-pages-sitemap-idiopathic-hypersomnia-js": () => import("./../../../src/pages/sitemap-idiopathic-hypersomnia.js" /* webpackChunkName: "component---src-pages-sitemap-idiopathic-hypersomnia-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-sms-terms-and-conditions-js": () => import("./../../../src/pages/sms-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-sms-terms-and-conditions-js" */),
  "component---src-pages-stay-connected-thank-you-js": () => import("./../../../src/pages/stay-connected-thank-you.js" /* webpackChunkName: "component---src-pages-stay-connected-thank-you-js" */),
  "component---src-pages-suc-thank-you-page-js": () => import("./../../../src/pages/suc-thank-you-page.js" /* webpackChunkName: "component---src-pages-suc-thank-you-page-js" */)
}

